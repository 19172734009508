.gantt_task_progress {
  background-color: rgb(141, 226, 141);
  height: 32px;
}
.gantt_task_line {
  background-color: #dfdfdf;
  border-color: rgb(40, 177, 40);
}
.gantt_task_content {
  color: black;
}
.gantt_grid_scale,
.gantt_task_scale,
.gantt_task_vscroll {
  background-color: #eee;
}
.gantt_grid_scale,
.gantt_task_scale,
.gantt_task .gantt_task_scale .gantt_scale_cell,
.gantt_grid_scale .gantt_grid_head_cell {
  color: #000;
  font-size: 13px;
  border-bottom: 1px solid #cecece;
}

.gantt_cal_ltext textarea,
.gantt_cal_ltext select {
  border-radius: 5px;
  padding: 8px 4px;
}

.gantt_cal_light {
  padding: 10px;
  height: fit-content !important;
  width: auto !important;
}

.gantt_section_time select {
  border-radius: 4px;
  padding: 5px 3px;
}

.statusChip {
  height: 24px;
  border-radius: 3px;
  line-height: 16px;
  padding: 4px 8px;
  font-size: 14px;
}

.done {
  background-color: #aeffa7;
  color: green;
}

.progress {
  background-color: #9ad1ef;
  color: #054077;
}

.todo {
  background-color: #edcbb7;
  color: #d73905;
}

.testing {
  background-color: #ffe663;
  color: #ec8e00;
}

.gantt_tree_content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon.task {
  color: blue;
  background-image: url(icon/task.png);
}
.icon.story {
  color: green;
  background-image: url(icon/story.png);
}
.icon.bug {
  color: red;
  background-image: url(icon/bug.png);
}

.icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  margin-left: 4px;
  background-size: cover;
  flex-shrink: 0;
}

.gantt_tree_icon.gantt_blank {
  width: 0px;
}
.gantt_cell.gantt_cell_tree {
  align-items: center;
}
