// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif !important;
  line-height: 1.5;
  overflow-y: overlay;
}

div * {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px ;
}
