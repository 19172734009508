.scrum {
  display: flex;
  align-items: flex-start;
  padding: 20px 0px;

  &__section {
    width: 300px;
    background-color: #e8e8e8;
    padding: 10px 5px;
    border-radius: 5px;

    & ~ & {
      margin-left: 10px;
    }

    &__title {
      font-size: 16px;
      font-weight: 700;
      margin: 0px 0px 10px;

      &__num {
        font-size: 12px;
        font-weight: 400;
      }
    }

    &__content {
      & > * ~ * {
        margin-top: 10px;
      }
    }
  }
}
